<template>
  <div class="candidate-details">
    <LoadingOverlay v-if="!isFetchingCandidatesDetails" />
    <div v-else-if="isCandidateFound">
      <div
        v-if="isCandidateRole"
        class="pb-3 mt-2 container-md col-lg-10 col-md-10"
      >
        <div class="text-right">
          <CButton
            class="spacingHomeBtn spacingHomeBtnRes"
            color="primary"
            shape="pill"
            @click="backtocardview"
            >Back to Home</CButton
          >
        </div>
      </div>
      <div class="pb-3" v-if="!isCandidateRole">
        <CRow class="m-0">
          <CCol md="12" sm="12" lg="6" class="d-block d-lg-none pb-3 pt-3">
            <div v-if="currentUserRole === 'customer_user'" class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back to Activity</CButton
              >
            </div>
            <div v-else class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="prevbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousCandidateId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextCandidateId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
          <CCol md="12" sm="12" lg="6">
            <h3 class="text-primary main-title">Candidate Detailed View</h3>
          </CCol>
          <CCol md="12" sm="12" lg="6" class="d-none d-lg-block">
            <div v-if="currentUserRole === 'customer_user'" class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back to Activity</CButton
              >
            </div>
            <div v-else class="float-right">
              <CButton
                class="mr-2 nowrap"
                color="primary"
                shape="pill"
                @click="backtocardview"
                >Back</CButton
              >
              <CButton
                id="prevbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getPreviousCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getPreviousCandidateId)"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                id="nextbtn"
                class="mr-2 nowrap"
                sm="4"
                :disabled="!getNextCandidateId"
                color="primary"
                shape="pill"
                @click="navigateto(getNextCandidateId)"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </CCol>
        </CRow>
      </div>

      <CRow class="m-0 pb-4 mb-3">
        <CCol :lg="isCandidateRole ? 12 : 8">
          <CCard
            :class="
              isCandidateRole ? 'px-0 container-md col-lg-10- col-md-10' : ''
            "
          >
            <CCardBody>
              <CRow class="pb-3 f-12">
                <CCol lg="4" class="pb-2 px-0 dark-block">
                  <div>
                    <CandidateInfo
                      :profile="profile"
                      :resumeDocument="resumeDocument"
                      :agencyCVDocument="agencyCVDocument"
                    />
                  </div>
                  <div v-if="getCandidateOrganisationByAdmin.length > 0 && 
                        currentUserRole === Role.customerAdmin && isTFWPool">
                   <div class="top-line">
                      <h6 class="text-primary">Registered Organisations</h6>
                      <div v-for="(org, index) in getCandidateOrganisationByAdmin" :key="index" 
                        style="color: black;"
                        class="mb-2">
                        <span>{{ getCandidateOrgs(org) }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                        <span>{{ formatDate(org?.created_on) }}</span>
                      </div>
                   </div>
                  </div>
                  <div>
                    <ContactInfo
                      v-if="!isJobSupplier"
                      :profile="profile"
                      :isCandidate="true"
                      :isEditable="isEditable"
                    />
                  </div>
                </CCol>
                <CCol lg="8">
                  <CRow class="mb-2">
                    <CCol lg="12">
                      <BasicInfo :profile="profile" :isEditable="isEditable" />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol lg="12" v-if="isNurse">
                      <ProfessionalInfoNurse
                        :profile="profile"
                        :nurseSpeciality="nurseSpeciality"
                        :specialInterest="specialInterest"
                        :qualifications="qualifications"
                        :isEditable="isEditable"
                      />
                    </CCol>
                    <CCol lg="12" v-else-if="isNonClinicalAdmin">
                      <ProfessionalInfoNonClinical
                        :profile="profile"
                        :qualifications="qualifications"
                        :isEditable="isEditable"
                      />
                    </CCol>
                    <CCol lg="12" v-else-if="isAHP">
                      <ProfessionalInfoAHP
                        :profile="profile"
                        :nurseSpeciality="nurseSpeciality"
                        :specialInterest="specialInterest"
                        :isEditable="isEditable"
                      />
                    </CCol>
                    <CCol lg="12" v-else>
                      <ProfessionalInfo
                        :profile="profile"
                        :isEditable="isEditable"
                      />
                    </CCol>
                    <CCol lg="12">
                      <AvailabilityInfo
                        :profile="profile"
                        :isEditable="isEditable"
                      />
                    </CCol>
                    <CCol lg="12">
                      <CandidateLanguageInfo />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard
            :class="
              isCandidateRole ? 'px-0 container-md col-lg-10 col-md-10' : ''
            "
          >
            <CCardBody>
              <CButton
                v-if="isCandidateRole"
                @click="navigateToEditProfile()"
                class="btn-primary float-right ml-2"
                title="Edit Section"
              >
                Edit Section</CButton
              >
              <GenericTabFilters
                :isShowRefresh="genericTabFilter.isShowRefresh"
                :refreshCallBack="refreshCallBack"
                :filterCallBack="filterCallBack"
                :activeTab="candidateInformationStep"
                :tabSelectCallBack="tabSelectCallBack"
                :isShowFavourite="true"
                page="CandidateDetails"
                :tabs="tabs"
                ref="GenericTabFilters"
              />

              <div class="info-content mt-2">
                <div
                  v-if="candidateInformationStep === 0 && !getTabInfo['tab_id']"
                >
                  <ProfileInfo
                    :profile="profile"
                    :qualifications="qualifications"
                    :resumeDocument="
                      isJobSupplier && !isCandidateInRecruitmentProcess
                        ? []
                        : resumeDocument
                    "
                    :documents="
                      isJobSupplier && !isCandidateInRecruitmentProcess
                        ? agencyCVDocument
                        : documents
                    "
                    ref="ProfileInfo"
                  />
                </div>
                <div v-if="candidateInformationStep && getTabInfo['tab_id']">
                  <JobMatch
                    :jobs="jobs"
                    :selectedJob="jobDetails"
                    @jobSelect="handleJobSelect"
                    @actionModalEvent="actionModalEvent"
                    :tabInfo="getTabInfo"
                  />
                  <div
                    v-if="noMoreJobs && jobs.length"
                    class="text-center mt-2"
                  >
                    <CBadge color="primary" class="font-weight-normal"
                      ><span>No More Jobs Found</span></CBadge
                    >
                  </div>
                </div>
                <div v-if="candidateInformationStep === 5">
                  <AuditHistory :candidateProfile="profile" />
                </div>
                <div v-if="candidateInformationStep === 7">
                  <Schedules
                    :candidateProfile="profile"
                    :schedule="getCandidateInterviewSchedules"
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg="4" id="jobDetails">
          <CCard v-if="isJobDetailsEmpty">
            <CCardBody class="f-12">
              <JobDetails
                :jobDetails="jobDetails"
                :getJobDetails="handleJobSelect"
                :actionModalEvent="actionModalEvent"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <h4 v-else class="text-muted text-center pt-4 mt-4">No Candidate Found</h4>

    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
    </CToaster>
    <ActionModal
      v-if="actionModal.isShowPopup"
      :isShowPopup="actionModal.isShowPopup"
      :details="actionModal.details"
      :action="actionModal.action"
      @modalCallBack="actionModalCallBack"
      @openFrontSheet="openFrontSheet"
      :candidate="actionModal.candidate"
      :job="actionModal.job"
    />
    <FastTrackModal
      v-if="fastTrackModal.isShowPopup"
      :isShowPopup="fastTrackModal.isShowPopup"
      :details="fastTrackModal.details"
      :action="fastTrackModal.action"
      @modalCallBack="fastTrackModalCallBack"
      :candidateDetail="fastTrackModal.candidate"
      :jobDetail="fastTrackModal.job"
    />
    <FrontSheetModal
      v-if="frontSheetModal.isShowPopup"
      :isShowPopup="frontSheetModal.isShowPopup"
      @modalCallBack="frontSheetModalCallBack"
      :candidateDetail="frontSheetModal.candidate"
      :jobDetail="frontSheetModal.job"
      :frontSheets="frontSheetModal.comments"
      :frontSheetInfo="frontSheetModal.frontSheetInfo"
    />
    <Modal
      v-if="confirmationModal.isShowPopup"
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :modalContent="confirmationModal.modalContent"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="confirmationModalCallBack"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />
    <SubmissionModal
      :modalTitle="submissionModal.modalTitle"
      :modalColor="submissionModal.modalColor"
      :modalContent="submissionModal.modalContent"
      :isShowPopup="submissionModal.isShowPopup"
      :buttons="submissionModal.buttons"
      :modalCallBack="submissionModalCallBack"
      :closeOnBackdrop="submissionModal.closeOnBackdrop"
      v-if="submissionModal.isShowPopup"
    />
    <OnErrorModal
      v-if="errorModal.isShowPopup"
      :isShowPopup="errorModal.isShowPopup"
      @modalCallBack="errorModalCallBack"
      :candidate="errorModal.candidate"
      :job="errorModal.job"
      :action="errorModal.action"
    />
    <Modal
      v-if="interViewModal.isShowPopup"
      :modalTitle="interViewModal.modalTitle"
      :modalColor="interViewModal.modalColor"
      :modalContent="interViewModal.modalContent"
      :isShowPopup="interViewModal.isShowPopup"
      :buttons="interViewModal.buttons"
      :modalCallBack="interViewModalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      v-if="notificationModal.isShowPopup"
      :modalTitle="notificationModal.modalTitle"
      :modalColor="notificationModal.modalColor"
      :modalContent="notificationModal.modalContent"
      :isShowPopup="notificationModal.isShowPopup"
      :buttons="notificationModal.buttons"
      :modalCallBack="notificationModalCallBack"
      :closeOnBackdrop="false"
    />
    <OfferOnBoardModal
      v-if="offerOnBoardModal.isShowPopup"
      :isShowPopup="offerOnBoardModal.isShowPopup"
      :title="offerOnBoardModal.title"
      :infoData="offerOnBoardModal.details"
      :action="offerOnBoardModal.action"
      :isOnBoard="offerOnBoardModal.isOnBoard"
      :isOffer="offerOnBoardModal.isOffer"
      @actionCallBack="offerOnBoardModalCallBack"
    />
    <CandidateOnboardDocsModal :onboardModel="onboardModel" />
    <CToaster
      v-if="getUpdateToast.isShow"
      :autohide="getUpdateToast.autohide"
      position="bottom-center"
    >
      <CToast :class="getUpdateToast.cssClass" :show="true">{{
        getUpdateToast.msg || ""
      }}</CToast>
    </CToaster>
    <CheckListModal
      v-if="checkListModal.isShowPopup"
      :isShowPopup="checkListModal.isShowPopup"
      @modalCallBack="checkListModalCallBack"
      :candidateDetails="checkListModal.candidateDetails"
      :jobDetails="checkListModal.jobDetails"
      :actionInfo="checkListModal.actionInfo"
      :isActionCompleted="checkListModal.isActionCompleted"
      :activeTab="checkListModal.activeTab"
      :accordion="checkListModal.accordion"
      @uploadDocActionCallBack="uploadDocActionCallBack"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { isObject, isEmptyObjectCheck, getScope, Role } from "@/helpers/helper";
import moment from "moment";
import Select from "@/components/reusable/Fields/Select";

import CandidateInfo from "@/containers/CandidateDetailsTalentFind/CandidateInfo";
import BasicInfo from "@/containers/CandidateDetailsTalentFind/BasicInfo";
import ContactInfo from "@/containers/CandidateDetailsTalentFind/ContactInfo";
import ProfessionalInfo from "@/containers/CandidateDetailsTalentFind/ProfessionalInfo";
import AvailabilityInfo from "@/containers/CandidateDetailsTalentFind/AvailabilityInfo";
import CandidateLanguageInfo from "@/containers/CandidateDetailsTalentFind/CandidateLanguageInfo";
import ProfileInfo from "@/containers/CandidateDetailsTalentFind/ProfileInfo";

import JobDetails from "@/containers/CandidateDetailsTalentFind/JobDetails";
import JobMatch from "@/containers/CandidateDetailsTalentFind/Job/JobMatch";
import JobInProcess from "@/containers/CandidateDetailsTalentFind/Job/JobInProcess";
import JobToDo from "@/containers/CandidateDetailsTalentFind/Job/JobToDo";
import Alerts from "@/containers/CandidateDetailsTalentFind/Alerts";
import AuditHistory from "@/containers/CandidateDetailsTalentFind/AuditHistory/AuditHistory";
import GenericTabFilters from "@/components/reusable/GenericTabFilters";
import ActionModal from "@/components/reusable/GenericTabFilters/ActionModal";
import FastTrackModal from "@/components/reusable/GenericTabFilters/FastTrackModal";
import OfferOnBoardModal from "@/components/reusable/OfferOnBoardModal";
import FrontSheetModal from "@/containers/Frontsheet/FrontSheetModal";
import OnErrorModal from "@/components/reusable/GenericTabFilters/OnErrorModal";

import ProfessionalInfoNurse from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoNurse";
import ProfessionalInfoNonClinical from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoNonClinical";
import ProfessionalInfoAHP from "@/containers/CandidateDetailsTalentFind/ProfessionalInfoAHP";

import SubmissionModal from "@/components/reusable/Modal";
import Modal from "@/components/reusable/Modal";

import CandidateOfferDocsModal from "../containers/JobsDetailedView/CandidateOfferDocsModal";
import CandidateOnboardDocsModal from "../containers/JobsDetailedView/CandidateOnBoardDocsModal";

import isJobSupplier from "@/mixins/isJobSupplier";
import _ from "lodash";

import Schedules from "@/containers/CandidateDetailsTalentFind/Schedule/Schedules";
import urlParse from "url";
import { getFilterQueryStringWithoutArray } from "../helpers/helper";
import CheckListModal from "@/components/reusable/CheckList/CheckListModal";
export default {
  name: "CandidateDetails",
  mixins: [isJobSupplier],
  components: {
    CandidateInfo,
    BasicInfo,
    ContactInfo,
    ProfessionalInfo,
    AvailabilityInfo,
    JobDetails,
    ProfileInfo,
    JobMatch,
    JobInProcess,
    JobToDo,
    Alerts,
    AuditHistory,
    Select,
    CandidateLanguageInfo,
    GenericTabFilters,
    ActionModal,
    ProfessionalInfoNurse,
    ProfessionalInfoNonClinical,
    ProfessionalInfoAHP,
    SubmissionModal,
    Modal,
    CandidateOfferDocsModal,
    CandidateOnboardDocsModal,
    Schedules,
    OfferOnBoardModal,
    FastTrackModal,
    FrontSheetModal,
    OnErrorModal,
    CheckListModal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      informations: [
        "Profile",
        "Matches",
        "In-Process",
        "To-Do's",
        "Alerts",
        "History",
      ],
      jobDetails: null,
      secondaryFilters: {},
      genericTabFilter: { isShowRefresh: false },
      actionModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
        candidate: null,
        job: null,
      },
      fastTrackModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
        candidate: null,
        job: null,
      },
      frontSheetModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        comments: [],
        frontSheetInfo: null,
      },
      errorModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        action: null,
      },
      offerOnBoardModal: {
        isShowPopup: false,
        job_id: null,
        details: null,
        action: null,
        title: null,
        isOffer: false,
        isOnBoard: false,
      },
      isMatchedJob: false,
      isMobile: null,
      submissionModal: {
        modalTitle: "NOTIFICATION",
        modalColor: "primary",
        modalContent: "Is the profile ready for submission?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
        closeOnBackdrop: false,
      },
      confirmationModal: {
        modalTitle: "NOTIFICATION",
        modalColor: "primary",
        modalContent: "Frontsheet Not Available for this Job & Candidate",
        isShowPopup: false,
        buttons: [
          { class: "secondary", button: "Close" },
          { class: "primary", button: "Create" },
        ],
        closeOnBackdrop: false,
      },
      interViewModal: {
        modalColor: "primary",
        modalTitle: "Interview Confirmation",
        modalContent: "Is the profile ready for interview?",
        buttons: ["No", "Yes"],
        isShowPopup: false,
      },
      notificationModal: {
        modalColor: "danger",
        modalTitle: "Notification",
        modalContent:
          "This account is entitled only up to Offer; please contact your Administrator for further access!",
        buttons: ["OK"],
        isShowPopup: false,
      },
      offerModel: {
        isShowPopup: false,
        data: null,
      },
      onboardModel: {
        isShowPopup: false,
        data: null,
      },
      checkListModal: {
        isShowPopup: false,
        candidateDetails: null,
        jobDetails: null,
        actionInfo: null,
        isActionCompleted: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "candidateInformationStep",
      "candidateProfile",
      "isFetchingCandidatesDetails",
      "candidateListBackUrl",
      "getPreviousCandidateId",
      "getNextCandidateId",
      "getUploadedDocument",
      "getCandidateQualification",
      "getCandidateLanguage",
      // "getCandidateSpecialInterest",
      "getLicense",
      "getSkills",
      "getCandidatePQRLevels",
      "getCustomerId",
      "getQueryResult",
      "getJobs",
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "getJobListForCandidateID",
      "noMoreJobs",
      "getJobListForCandidateIDCount",
      "getTabInfo",
      "getJobDetailsForCandidateID",
      "getDisplayStatusSubStatus",
      "getJobCandidateTabs",
      "getUpdateToast",
      "getCandidateJobPagination",
      "getOfferDetailsforCandidate",
      "isCandidateSupplierFromAccessToken",
      "getCandidateInterviewSchedules",
      "getJobDetailsById",
      "isUKMainAgency",
      "getCandidatePaginationPageNumber",
      "isCandidateRoleFromAccessToken",
      "currentUserType",
      "jobCandidateFrontSheet",
      "getOrgIDFromAccessToken",
      "getCandidateOrganisationByAdmin",
      "getCustomerTypeIDFromAccessToken",
    ]),
    options() {
      return {
        filterMatches: [
          { code: null, label: "All" },
          { code: true, label: "Applied" },
          { code: false, label: "Talent Pool" },
        ],
      };
    },
    isTFWPool() {
      const { params } = this.$route;
      return params?.customer_type === "super_customer" || false;
    },
    isEditable() {
      return (
        ([Role.customerAdmin, Role.customerRecruiter].includes(
          this.currentUserType?.code || this.currentUserRole
        ) &&
          !this.isJobSupplier &&
          !this.isTFWPool) ||
        false
      );
    },
    jobs() {
      if (
        this.getJobListForCandidateID &&
        this.getJobListForCandidateID.length
      ) {
        if (this.jobDetails == null) {
          let { query } = this.$route;
          let filteredJob = [];
          if (query?.job_id) {
            const job_id = parseInt(query?.job_id);
            filteredJob = this.getJobListForCandidateID.filter(
              (job) => job?.job_id === job_id
            );
          }
          let job = this.getJobListForCandidateID[0];
          if (filteredJob.length) {
            job = filteredJob[0];
          }
          this.handleJobSelect(job, false);
        }
        return this.getJobListForCandidateID;
      }
      this.jobDetails = null;
      return [];
    },
    jobInfo() {
      return this.jobDetails?.job;
    },
    isJobDetailsEmpty() {
      return isObject(this.jobInfo) && !isEmptyObjectCheck(this.jobInfo);
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    isCustomerUserRole() {
      return this.currentUserRole === Role.customerUser;
    },
    isCandidateInRecruitmentProcess() {
      return this.candidateProfile?.currently_in_recruitment_process || false;
    },
    profile() {
      if (
        isObject(this.candidateProfile) &&
        !isEmptyObjectCheck(this.candidateProfile)
      )
        return {
          ...this.candidateProfile,
          candidateTypeName: this.candidateProfile.candidate_type
            ? this.candidateProfile.candidate_type.candidate_type
            : null,
          phoneNumber: this.candidateProfile.phone
            ? this.candidateProfile.phone.replace("^", "-")
            : null,
          alternatePhoneNumber: this.candidateProfile.alternate_phone
            ? this.candidateProfile.alternate_phone.replace("^", "-")
            : null,
          whatsappNumber: this.candidateProfile.whatsapp
            ? this.candidateProfile.whatsapp.replace("^", "-")
            : null,
          genderName: this.candidateProfile.gender
            ? this.candidateProfile.gender.gender
            : null,
          nationalityName: this.candidateProfile.nationality_names
            ? this.candidateProfile.nationality_names.join(", ")
            : null,
          birthPlaceName:
            this.candidateProfile?.birth_place?.country_name || null,
          lastLoginDate: this.candidateProfile.last_date_of_login
            ? moment(this.candidateProfile.last_date_of_login).format(
                "DD-MMM-YYYY"
              )
            : "--",
          createdOnDate: this.candidateProfile.created_on
            ? moment(this.candidateProfile.created_on).format("DD-MMM-YYYY")
            : "--",
          _title: this.candidateProfile?.title?.title || null,
        };
      return {};
    },
    isCandidateFound() {
      return (
        this.isFetchingCandidatesDetails &&
        isObject(this.candidateProfile) &&
        !isEmptyObjectCheck(this.candidateProfile)
      );
    },
    documents() {
      let excludeDocTypes = [1, 23, 24, 25, 27, 26, 28, 29, 30];
      if (
        this.isCandidateRoleFromAccessToken ||
        this.isCandidateSupplierFromAccessToken
      )
        excludeDocTypes.push(20);
      return (
        (isObject(this.candidateProfile) &&
          !isEmptyObjectCheck(this.candidateProfile) &&
          this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(
              ({ document_type_id }) =>
                !excludeDocTypes.includes(document_type_id)
            )
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
              }) => {
                return {
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  document_type_id: document_type_id,
                };
              }
            )) ||
        []
      );
    },
    resumeDocument() {
      return (
        (isObject(this.candidateProfile) &&
          !isEmptyObjectCheck(this.candidateProfile) &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 1)
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
                version_no,
              }) => {
                return {
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no: version_no,
                };
              }
            )) ||
        []
      );
    },
    agencyCVDocument() {
      return (
        (isObject(this.candidateProfile) &&
          !isEmptyObjectCheck(this.candidateProfile) &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 20)
            .map(
              ({
                document_type_id,
                link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
                version_no,
              }) => {
                return {
                  link: link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no: version_no,
                };
              }
            )) ||
        []
      );
    },
    qualifications() {
      return (
        (this.getCandidateQualification &&
          this.getCandidateQualification.map(
            ({
              candidate_qualification_id,
              qualification_id,
              qualification_name,
              qualification,
              country_id,
              country_name,
              year_awarded,
              month_awarded,
              speciality_name,
              sub_speciality_name,
              issue_authority_id,
              issue_authority_name,
              other_issue_authority,
              course_duration,
              distance_learning,
              description,
              comments,
              by_exam,
              other_qualification_name,
              document_ids,
              documents,
              main,
              primary,
            }) => {
              return {
                candidate_qualification_id: candidate_qualification_id,
                qualification_id: qualification_id,
                qualification_name: qualification_name,
                qualification_with_acronym:
                  qualification?.qualification_with_acronym,
                qualification: qualification,
                country_id: country_id,
                country_name: country_name,
                year_awarded: year_awarded,
                month_awarded: month_awarded,
                speciality_name: speciality_name,
                sub_speciality_name: sub_speciality_name,
                issue_authority_id: issue_authority_id,
                issue_authority_name: issue_authority_name,
                other_issue_authority: other_issue_authority,
                course_duration: course_duration,
                distance_learning: distance_learning,
                description: description,
                comments: comments,
                by_exam: by_exam,
                other_qualification_name: other_qualification_name,
                document_ids: document_ids,
                documents: documents,
                main: main,
                primary: primary,
                isFirst:
                  (main && this.profile?.qualifications.length === 1) ||
                  (!main && this.profile?.qualifications.length > 1),
                isPG: qualification?.post_graduate_qual || false,
              };
            }
          )) ||
        []
      );
    },
    qualifications2D() {
      return this.qualifications.reduce((a, c, i) => {
        return i % 2 === 0
          ? a.concat([this.qualifications.slice(i, i + 2)])
          : a;
      }, []);
    },
    isJobDetailsNotEmpty() {
      return isObject(this.jobInfo) && !isEmptyObjectCheck(this.jobInfo);
    },
    tabs() {
      let tabs = [{ tab_id: null, tab_name: "Profile" }];
      if (
        !this.isCandidateRole &&
        !this.isCustomerUserRole &&
        !this.isTFWPool
      ) {
        const dynamicTabs = this.getJobCandidateTabs || [];
        tabs = [
          ...tabs,
          ...dynamicTabs,
          { tab_id: null, tab_name: "History" },
          { tab_id: null, tab_name: "Alerts" },
          {
            tab_id: null,
            tab_name: "Schedules",
            count: this.pendingInterview.length || 0,
          },
        ];
      }
      return tabs;
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isNonClinicalAdmin() {
      return this.profile?.candidate_type_id === 6 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
    nurseSpeciality() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ speciality_id, special_interest_id, other_special_interest }) =>
              speciality_id &&
              special_interest_id === null &&
              other_special_interest === null
          )) ||
        []
      );
    },
    specialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities.filter(
            ({ special_interest_id, other_special_interest }) =>
              special_interest_id || other_special_interest
          )) ||
        []
      );
    },
    pendingInterview() {
      return (
        this.getCandidateInterviewSchedules.filter(
          (v) => v.status_id === 54 && v.sub_status_id === 55
        ) || []
      );
    },
  },
  mounted() {
    let appendAction = [];
    if (
      this.isCandidateRole &&
      isObject(this.candidateProfile) &&
      !isEmptyObjectCheck(this.candidateProfile)
    ) {
      appendAction = [
        ...appendAction,
        this.commitCandidateInfo(this.candidateProfile),
      ];
    } else {
      appendAction = [
        ...appendAction,
        this.setSelectedcandidateById(this.$route.params.id),
      ];
    }
    const { query } = this.$route;
    if (!this.isCandidateRole && !this.isCustomerUserRole && !this.isTFWPool) {
      this.$store.commit("SET_CANDIDATE_INFORMATION_STEP", 1);
      appendAction = [...appendAction, this.fetchJobCandidateTabs()];
    } else {
      appendAction = [
        ...appendAction,
        this.$store.commit("SET_CANDIDATE_INFORMATION_STEP", 0),
      ];
    }
    if (query?.limit) {
      this.$store.commit("SET_CANDIDATE_JOB_PAGINATION", {
        limit: query?.limit,
      });
    }
    Promise.all(appendAction).then(async (res) => {
      if (query?.tab_index) {
        const tab_step = parseInt(query?.tab_index);
        this.$store.commit("SET_CANDIDATE_INFORMATION_STEP", tab_step);
      } 
      await this.initCandidateDetailsFetch();
      this.fetchCandidateInterview();
      this.$nextTick(() => {
        const ele = this.$refs.GenericTabFilters;
        if (ele) {
          ele.initEvent();
        }
      });
      // if (this.isCandidateFound) await this.initCandidateDetailsFetch();
      this.jobDetails = null;
      this.fetchOfferOnboardDocs();
      if (!this.getNextCandidateId) this.fetchMoreCandidateList();
    });
    this.scrollTop();

    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }

    this.isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    "$route.params.id": function (id) {
      const appendAction = this.setSelectedcandidateById(this.$route.params.id);
      Promise.all([appendAction]).then(async (res) => {
        await this.initCandidateDetailsFetch();
        this.jobDetails = null;
        if (this.candidateInformationStep === 0)
          this.$refs.ProfileInfo.resetExpand();
        if (this.getTabInfo?.tab_id)
          this.fetchJobListForCandidateID({
            pagination: false,
          });
        await this.fetchCandidateInterview();
        if (!this.getNextCandidateId) {
          await this.fetchMoreCandidateList();
        }
        const oldBackURL = this.candidateListBackUrl;
        const urlObj = urlParse.parse(oldBackURL, true);
        if (urlObj && urlObj.pathname.includes("candidate-list")) {
          let { pathname, query } = urlObj;
          query.page = this.getCandidatePaginationPageNumber;
          const newBackURL = `${pathname}?${getFilterQueryStringWithoutArray(
            query
          )}`;
          this.updateCandidateListBackUrl({ oldBackURL, newBackURL });
        }
      });

      this.jobDetails = null;
    },
    candidateProfile() {
      if(this.candidateProfile?.user_id) {
        this.fetchCandidateOrganisationByAdmin(this.candidateProfile?.user_id)
      }
    },
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "setSelectedcandidateById",
      "fetchMoreCandidateList",
      "fetchJobs",
      "commitCandidateInfo",
      "downloadDocument",
      "initCandidateDetailsFetch",
      "getCandidateDetails",
      "deleteCandidate",
      "deactivateCandidate",
      "restoreCandidate",
      "reactivateCandidate",
      "getTotalSelectedCandidatesCount",
      "showToast",
      "fetchJobListForCandidateID",
      "triggerCandidateJobMatch",
      "initUpdateJobStatus",
      "updateCandidateJobStatus",
      "fetchJobCandidateTabs",
      "initJobCandidateCount",
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchCurrency",
      "fetchCandidateCommonComments",
      "fetchUploadedDocumentbyCandidateId",
      "fetchJobDocumentsByJobID",
      "getOffersForCandidate",
      "fetchOffersByCandidate",
      "getOrgDetailsById",
      "fetchCandidateInterview",
      "setSelectedjobById",
      "createDocumentAction",
      "getOfferDocumentAction",
      "getOnBoardDocumentAction",
      "updateCandidateListBackUrl",
      "fetchJobCandidateFrontSheet",
      "addJobCandidateFrontSheet",
      "fetchCandidateOrganisationByAdmin",
    ]),
    navigateToEditProfile() {
      this.$store.state.candidate.block = 0;
      this.$router.push("/profile");
    },
    backtocardview() {
      const backURL = this.candidateListBackUrl;
      this.$router.push(backURL).then((res) => {
        this.$store.commit(
          "REMOVE_CANDIDATE_LIST_BACK_URL",
          this.candidateListBackUrl
        );
      });
    },
    goToExpendableSection() {
      this.$refs.GenericTabFilters.selectTab(0, null);
      this.tabSelectCallBack({ index: 0 });
      setTimeout(() => {
        this.$refs.ProfileInfo.expandAll();
        let el = this.$el.getElementsByClassName("profile-info")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -70);
      }, 50);
    },
    navigateto(id) {
      this.fetchCandidateCommonComments(id);
      this.$router.push({ path: `/candidate/${id}` }).then((res) => {
        // let appendAction = this.setSelectedcandidateById(this.$route.params.id);
        // Promise.all([appendAction]).then(async (res) => {
        //   await this.initCandidateDetailsFetch();
        //   this.jobDetails = null;
        //   if (this.candidateInformationStep === 0)
        //     this.$refs.ProfileInfo.resetExpand();
        //   if (this.getTabInfo?.tab_id)
        //     this.fetchJobListForCandidateID({
        //       pagination: false,
        //     });
        // });
      });
    },
    onBodyScroll(e) {
      if (this.noMoreJobs) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getDisplayStatusSubStatus.length &&
        this.getTabInfo?.tab_id &&
        !this.noMoreJobs
      ) {
        this.fetchJobListForCandidateID({
          pagination: true,
        }).then((res) => {
          const { skip, limit } = this.getCandidateJobPagination;
          this.updateQuery({ limit: skip + limit });
        });
      }
    },
    toTitleCase(str) {
      return str
        ? str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        : "--";
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
      return false;
    },
    handleJobSelect(job, ismovedTo) {
      this.jobDetails = job;
      const {
        job: { job_id, customer_uid: customer_id },
      } = job;
      this.updateQuery({ job_id });
      this.fetchJobBenefitsAdvertDescByJobId({ job_id, customer_id });
      this.fetchCurrency();
      if (ismovedTo !== false)
        setTimeout(() => {
          document.getElementById("jobDetails").scrollIntoView();
        }, 100);
    },
    updateQuery(v, reset = false) {
      let { query, path } = this.$route;
      if (reset) query = {};
      if (!_.isEqual(v, query))
        this.$router.replace({ path, query: { ...query, ...v } });
    },
    goToSplInterestSection() {
      this.candidateInformationStep = 0;
      setTimeout(() => {
        this.$refs.ProfileInfo.isExpand.specialInterest = true;
        let el = this.$el.getElementsByClassName("splInterest-info-section")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -70);
      }, 50);
    },
    submissionModalCallBack(action) {
      this.submissionModal["isShowPopup"] = false;
      if (action === "Yes") {
        this.actionModal["isShowPopup"] = true;
      } else {
        this.actionModal["isShowPopup"] = false;
      }
    },
    interViewModalCallBack(action) {
      this.interViewModal["isShowPopup"] = false;
      if (action === "Yes") {
        this.actionModal["isShowPopup"] = true;
      } else {
        this.actionModal["isShowPopup"] = false;
      }
    },
    notificationModalCallBack(action) {
      this.notificationModal.isShowPopup = false;
    },
    refreshCallBack() {
      let appendAction = this.triggerCandidateJobMatch();
      Promise.all([appendAction]).then((res) => {
        this.fetchJobListForCandidateID({
          pagination: false,
        });
        this.showToast({
          class: "bg-success text-white",
          message: "Job Match Refreshed.",
        });
      });
    },
    filterCallBack(isFavourite) {
      this.fetchJobListForCandidateID({
        pagination: false,
        isFavourite,
      });
    },
    tabSelectCallBack({ index, tab_id }) {
      this.updateQuery({ tab_index: index }, true);
      this.$store.commit("SET_CANDIDATE_INFORMATION_STEP", index);
      this.fetchCandidateInterview();
      // this.candidateInformationStep = index;
    },
    actionModalEvent(payload) {
      const { jobDetails, selectedAction } = payload;
      const { action_id, directAction, status, reason_id } = selectedAction;
      const { job_id, job } = jobDetails;
      const { candidate_uid } = this.profile;
      const candidate = this.profile;
      const actionData = {
        submission: 14,
        interview: 6,
        offer: 7,
        onboard: 8,
        fastTrack: 18,
        error: 4,
        documents: 16,
      };
      //Restricted for Demo Customer
      if (
        this.getCustomerId === 8 &&
        this.getOrgIDFromAccessToken === 7 &&
        [8, 16, 1].includes(action_id)
      ) {
        this.notificationModal.isShowPopup = true;
        return;
      }
      if (directAction) {
        let uploadPayload = {};
        uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload: { reason_id } },
        };
        uploadPayload = { ...uploadPayload, updateStatusSubStatus: status };
        this.updateCandidateJobStatus(uploadPayload).then((res) => {
          this.fetchJobListForCandidateID({ pagination: false });
          this.jobDetails = null;
          this.fetchCandidateInterview();
        });
      } else {
        this.actionModal["job_id"] = job_id;
        this.actionModal["details"] = jobDetails;
        this.actionModal["action"] = selectedAction;
        this.actionModal.job = jobDetails?.job;
        this.actionModal.candidate = this.profile;

        if (action_id === actionData.submission) {
          let appendAction = [this.setSelectedjobById(job_id)];
          appendAction = [
            ...appendAction,
            this.fetchJobCandidateFrontSheet({ job_id, candidate_uid }),
          ];
          Promise.all(appendAction).then(async (res) => {
            if (
              this.getJobDetailsById &&
              this.getJobDetailsById?.job_candidate_users &&
              this.getJobDetailsById?.job_candidate_users?.length
            ) {
              let candidate_reviewers = _(
                this.getJobDetailsById?.job_candidate_users
              )
                .filter(({ customer_user }) => {
                  return customer_user?.customer_user_type_id == 1;
                })
                .value();
              if (!candidate_reviewers?.length) {
                const msg =
                  "No Reviewer Found ! Please update a Submission Reviewer for the job !!";
                this.$store.commit("SET_UPDATE_TOAST_VISIBLE", {
                  isShow: true,
                  msg,
                  cssClass: "bg-danger text-white",
                });
                return;
              } else if (candidate_reviewers?.length) {
                this.frontSheetModal.comments = this.jobCandidateFrontSheet;
                if (this.jobCandidateFrontSheet.length) {
                  this.submissionModal["isShowPopup"] = true;
                } else {
                  this.confirmationModal["isShowPopup"] = true;
                  this.frontSheetModal.job = jobDetails?.job;
                  this.frontSheetModal.candidate = this.profile;
                  this.frontSheetModal.frontSheetInfo = null;
                }
              }
            } else {
              const msg =
                "No Reviewer Found ! Please update a Submission Reviewer for the job !!";
              this.$store.commit("SET_UPDATE_TOAST_VISIBLE", {
                isShow: true,
                msg,
                cssClass: "bg-danger text-white",
              });
              return;
            }
          });
        } else if (action_id === actionData.interview) {
          let appendAction = [this.setSelectedjobById(job_id)];
          Promise.all(appendAction).then(async (res) => {
            if (
              this.getJobDetailsById &&
              this.getJobDetailsById?.job_candidate_users &&
              this.getJobDetailsById?.job_candidate_users?.length
            ) {
              let candidate_interviewers = _(
                this.getJobDetailsById?.job_candidate_users
              )
                .filter(({ customer_user }) => {
                  return customer_user?.customer_user_type_id == 2;
                })
                .value();
              if (!candidate_interviewers?.length) {
                const msg =
                  "No Interviewer Found ! Please update a Candidate Interviewer for the job !!";
                this.$store.commit("SET_UPDATE_TOAST_VISIBLE", {
                  isShow: true,
                  msg,
                  cssClass: "bg-danger text-white",
                });
                return;
              } else if (candidate_interviewers?.length) {
                this.interViewModal["job_id"] = job_id;
                this.interViewModal["details"] = jobDetails;
                this.interViewModal["action"] = selectedAction;
                this.interViewModal["isShowPopup"] = true;
              }
            } else {
              const msg =
                "No Interviewer Found ! Please update a Candidate Interviewer for the job !!";
              this.$store.commit("SET_UPDATE_TOAST_VISIBLE", {
                isShow: true,
                msg,
                cssClass: "bg-danger text-white",
              });
              return;
            }
          });
        } else if (action_id === actionData.offer) {
          const checkListPayload = {
            ...jobDetails,
            organisation_id: job.organisation_uid,
            candidate,
          };
          this.openCheckList(checkListPayload, 1, "offer");
        } else if (action_id === actionData.onboard) {
          const checkListPayload = {
            ...jobDetails,
            organisation_id: job.organisation_uid,
            candidate,
          };
          this.openCheckList(checkListPayload, 1, "licensing");
        } else if (action_id === actionData.fastTrack) {
          this.fastTrackModal["job_id"] = job_id;
          this.fastTrackModal["details"] = jobDetails;
          this.fastTrackModal["action"] = selectedAction;
          this.fastTrackModal.isShowPopup = true;
          this.fastTrackModal["candidate"] = this.profile;
          this.fastTrackModal["job"] = job;
        } else if (action_id === actionData.error) {
          this.errorModal.job = jobDetails?.job;
          this.errorModal.candidate = this.profile;
          this.errorModal.action = selectedAction;
          this.errorModal["isShowPopup"] = true;
        } else if (action_id === actionData.documents) {
          const checkListPayload = {
            ...jobDetails,
            organisation_id: job.organisation_uid,
            candidate,
          };
          this.openCheckList(checkListPayload, 1, "offer");
        } else {
          this.actionModal["isShowPopup"] = true;
        }
      }
    },
    errorModalCallBack(action, payload) {
      this.errorModal.isShowPopup = false;
      if (!action) this.errorModal.isShowPopup = false;
      if (action && payload) {
        const { job } = this.errorModal;
        const { candidate } = this.errorModal;
        const { job_id } = job;
        const { candidate_uid } = candidate;
        const {
          status_id: update_status,
          sub_status_id: update_sub_status,
          comments,
        } = payload;
        let uploadPayload = {
          updateStatusSubStatus: {
            update_status,
            update_sub_status,
          },
        };
        uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload: { comments } },
        };
        this.updateCandidateJobStatus(uploadPayload).then((res) => {
          this.fetchJobListForCandidateID({ pagination: false });
          this.errorModal.isShowPopup = false;
          this.jobDetails = null;
          this.fetchCandidateInterview();
        });
      }
    },
    fastTrackModalCallBack(action, payload) {
      this.fastTrackModal.isShowPopup = false;
      if (!action) this.fastTrackModal.isShowPopup = false;
      if (action && payload) {
        const { job_id } = this.fastTrackModal;
        const { candidate_uid } = this.profile;
        let uploadPayload = {
          updateStatusSubStatus: {
            update_status: 54,
            update_sub_status: 55,
          },
        };
        uploadPayload = {
          ...uploadPayload,
          payloadData: { job_id, candidate_uid, payload },
        };
        this.updateCandidateJobStatus(uploadPayload).then((res) => {
          this.fetchJobListForCandidateID({ pagination: false });
          this.fastTrackModal.isShowPopup = false;
          this.fastTrackModal.job_id = null;
          this.jobDetails = null;
          this.fetchCandidateInterview();
        });
      }
    },
    actionModalCallBack(action, payload) {
      this.actionModal.isShowPopup = false;
      if (!action) this.actionModal.isShowPopup = false;
      if (action && payload) {
        const { job_id } = this.actionModal;
        const { candidate_uid } = this.profile;
        const appentAction = this.initUpdateJobStatus({
          candidate_uid,
          job_id,
          payload,
        });
        Promise.all([appentAction]).then((res) => {
          if (res) {
            this.actionModal.isShowPopup = false;
            this.actionModal.job_id = null;
            this.jobDetails = null;
            this.fetchCandidateInterview();
            this.fetchJobListForCandidateID({ pagination: false });
          }
        });
      }
    },
    fetchOfferOnboardDocs() {
      const { candidate_uid } = this.candidateProfile;
      this.getOfferDocumentAction({
        action_type: "offer",
        candidate_uid,
      });
      this.getOnBoardDocumentAction({
        action_type: "licensing",
        candidate_uid,
      });
    },
    offerOnBoardModalCallBack(action, data) {
      if (action && data) {
        const { documentPayload, processPayload, actionPayload } = data;
        let appendAction = [];
        documentPayload.forEach((element) => {
          appendAction = [...appendAction, this.createDocumentAction(element)];
        });
        Promise.all(appendAction).then(async (res) => {
          await this.initUpdateJobStatus(processPayload);
          this.fetchJobListForCandidateID({ pagination: false });
          this.offerOnBoardModal.isShowPopup = false;
          this.offerOnBoardModal["job_id"] = null;
          this.offerOnBoardModal["details"] = null;
          this.offerOnBoardModal["action"] = null;
          this.fetchOfferOnboardDocs();
        });
      } else {
        this.offerOnBoardModal.isShowPopup = false;
      }
    },
    frontSheetModalCallBack(action, payload) {
      if (action && payload) {
        this.addJobCandidateFrontSheet(payload).then((res) => {
          this.frontSheetModal.isShowPopup = false;
          this.actionModal.isShowPopup = true;
        });
      } else {
        this.frontSheetModal.isShowPopup = false;
      }
    },
    confirmationModalCallBack(action) {
      if (action?.button === "Create") {
        this.frontSheetModal.isShowPopup = true;
      }
      this.confirmationModal.isShowPopup = false;
    },
    openFrontSheet(candidate = {}, job = {}) {
      const { candidate_uid } = candidate;
      const { job_id } = job;
      this.fetchJobCandidateFrontSheet({
        job_id,
        candidate_uid,
        order_by: "-job_candidate_front_sheet_id",
      }).then((res) => {
        if (res?.status === 200) {
          this.frontSheetModal.job = job;
          this.frontSheetModal.candidate = candidate;
          this.frontSheetModal.comments = res.data;
          this.frontSheetModal.frontSheetInfo = res?.data[0];
          this.frontSheetModal.isShowPopup = true;
          this.actionModal.isShowPopup = false;
        }
      });
    },
    openCheckList(data = {}, activeTab = 0, accordion = null) {
      const { candidate, job } = data;
      this.checkListModal.isShowPopup = true;
      this.checkListModal.actionInfo = data;
      this.checkListModal.candidateDetails = candidate;
      this.checkListModal.jobDetails = job;
      this.checkListModal.activeTab = activeTab;
      this.checkListModal.accordion = accordion;
    },
    checkListModalCallBack(action, data) {
      this.checkListModal.isShowPopup = false;
      this.fetchJobListForCandidateID({ pagination: false });
    },
    uploadDocActionCallBack(data) {
      if (data) {
        const { documentPayload, processPayload, actionPayload } = data;
        let appendAction = [];
        documentPayload.forEach((element) => {
          appendAction = [...appendAction, this.createDocumentAction(element)];
        });
        Promise.all(appendAction).then(async (res) => {
          if (processPayload) await this.initUpdateJobStatus(processPayload);
          this.checkListModal.isActionCompleted = true;
        });
      }
      return;
    },
    getCandidateOrgs(org) {
      return org?.organisation_name ? org?.organisation_name : 
              org?.customer_name ? org?.customer_name : ''
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "--";
    },
  },
};
</script>
